import { useTranslation } from "react-i18next";

import { check, generation, position, servicesInfo } from "../../../assets";
import { generationStepsIcons, initSteps } from "../../../constants";
import {
  Gradient,
  PhotoChatMessage,
  VideoBar,
  VideoChatMessage,
} from "../../design/HowToUseDesigns";
import Heading from "../../shared/Heading";
import Section from "../../shared/Section";

const HowToUse = () => {
  const { t } = useTranslation();

  return (
    <Section id="how-to-use">
      <div className="container pt-15 lg:pt-10">
        <Heading title="onboarding_title" text="onboarding_subtitle" />

        <div className="relative">
          <div className="relative z-1 mb-5 flex h-[45rem] items-center overflow-hidden rounded-3xl border border-n-1/10 p-4 md:p-8 lg:p-20 xl:h-[46rem]">
            <div className="flex size-full items-center md:flex-col lg:flex-row">
              <div className="absolute right-0 top-[-10%] hidden h-full w-auto py-15 sm:inline-flex lg:relative lg:w-full lg:py-5">
                <img src={servicesInfo} alt="info" className="size-full" />
              </div>

              <div className="relative z-1 size-full content-center items-center md:w-3/4 lg:w-full">
                <div className="flex flex-col gap-8 rounded-3xl bg-[#410c3a]/90 p-4 md:p-5 lg:bg-transparent">
                  <h4 className="h4 text-pretty">
                    {t("onboarding_step1_title")}
                  </h4>
                  <p className="body-2 text-wrap text-n-3">
                    {t("onboarding_step1_text")}
                  </p>
                  <ul className="body-2 text-wrap">
                    {initSteps.map((step, i) => (
                      <li
                        className="flex items-center border-t border-n-6 py-4"
                        key={i}
                      >
                        <img src={check} width={24} height={24} alt="check" />
                        <p className="ml-4">{t("onboarding_step1_" + step)}</p>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="relative z-1 grid gap-5 lg:grid-cols-2">
            <div className="relative min-h-[39rem] overflow-hidden rounded-3xl border border-n-1/10">
              <div className="flex size-full flex-row lg:flex-col">
                <div className="relative z-20 size-full lg:size-auto">
                  <PhotoChatMessage />
                </div>
                <div className="relative -top-10 size-full lg:-top-15">
                  <img
                    src={position}
                    className="absolute size-full scale-150 lg:left-20 lg:scale-100"
                    alt="tasks"
                  />
                </div>
              </div>

              <div className="absolute inset-0 flex flex-col justify-end gap-4 text-balance bg-gradient-to-b from-n-8/0 to-n-8/90 p-8 lg:p-15">
                <h4 className="h4">{t("onboarding_step2_title")}</h4>
                <p className="body-2 text-n-3">{t("onboarding_step2_text")}</p>
              </div>
            </div>

            <div className="overflow-hidden rounded-3xl bg-n-7 p-4 lg:min-h-[46rem]">
              <div className="flex flex-col gap-8 px-4 py-12 lg:px-8">
                <h4 className="h4">{t("onboarding_step3_title")}</h4>
                <p className="body-2  text-n-3">{t("onboarding_step3_text")}</p>

                <ul className="flex items-center justify-between">
                  {generationStepsIcons.map((icon, i) => (
                    <li
                      className={`flex items-center justify-center rounded-2xl ${
                        i === 2
                          ? "size-12 bg-conic-gradient p-0.25 md:size-[4.5rem]"
                          : "flex size-10 bg-n-6 md:size-15"
                      }`}
                      key={i}
                    >
                      <div
                        className={
                          i === 2
                            ? "flex size-full items-center justify-center rounded-2xl bg-n-7"
                            : ""
                        }
                      >
                        <img src={icon} width={24} height={24} alt={icon} />
                      </div>
                    </li>
                  ))}
                </ul>
              </div>

              <div className="relative h-80 overflow-hidden rounded-xl bg-n-8 md:h-[25rem]">
                <div className="flex size-full flex-row">
                  <div className="relative size-full py-10">
                    <img
                      src={generation}
                      className="size-full"
                      alt="generation"
                    />
                  </div>

                  <div className="relative mx-5 my-10 size-full">
                    <VideoChatMessage />
                  </div>
                </div>

                <VideoBar />
              </div>
            </div>
          </div>

          <Gradient />
        </div>
      </div>
    </Section>
  );
};

export default HowToUse;
