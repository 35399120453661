import { useTranslation } from "react-i18next";

import { languages } from "../../constants";

const LangSelector = ({ className, width, height }) => {
  const { i18n } = useTranslation();

  return (
    <div
      className={`items-center justify-center rounded-full transition-colors hover:cursor-pointer ${
        className || ""
      }`}
      onClick={() => {
        i18n.changeLanguage(
          i18n.resolvedLanguage === languages[0].code
            ? languages[1].code
            : languages[0].code
        );
      }}
    >
      <img
        src={
          i18n.resolvedLanguage === languages[0].code
            ? languages[1].icon
            : languages[0].icon
        }
        width={width}
        height={height}
        alt={
          i18n.resolvedLanguage === languages[0].code
            ? languages[1].code
            : languages[0].code
        }
      />
    </div>
  );
};

export default LangSelector;
