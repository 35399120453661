import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HTTPApi from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(HTTPApi)
  .init({
    load: "languageOnly",
    supportedLngs: ["el", "en"],
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ["cookie", "navigator"],
      lookupCookie: "BEEEASE_LANG",
      caches: ["cookie"],
      cookieOptions: { path: "/", sameSite: "strict" },
      htmlTag: document.documentElement,
      cookieMinutes: 60 * 24 * 365,
      convertDetectedLanguage: (lng) => {
        if (lng.includes("el")) return "el";
        return "en";
      },
    },
  });

export default i18next;
