import { useTranslation } from "react-i18next";

import { check } from "../../../assets";
import { pricingPackages } from "../../../constants";
import Button from "../../shared/Button";

const List = ({ monthlyOptions }) => {
  const { t } = useTranslation();

  return (
    <div className="flex gap-5 max-lg:flex-wrap 2xl:gap-10">
      {pricingPackages.map((item) => (
        <div
          className="flex h-full w-[19rem] flex-col gap-8 rounded-[2rem] border border-n-6 bg-n-8 px-6 odd:my-4 odd:py-8 even:py-14 max-lg:w-full lg:w-auto [&>h4]:first:text-color-2 [&>h4]:last:text-color-3 [&>h4]:even:text-color-1"
          key={item.id}
        >
          <h4 className="h4">{t("pricing_" + item.name + "_name")}</h4>
          <p className="body-2 text-n-1/50 lg:text-wrap">
            {t("pricing_" + item.name + "_text")}
          </p>

          <div className="flex items-center self-end">
            {item.price ? (
              <>
                <div className="h4">&#8364;</div>
                <div className="h1 font-bold leading-none">
                  {monthlyOptions ? item.monthly : item.annually}
                </div>
              </>
            ) : (
              <div className="hidden h-12 lg:inline-flex"></div>
            )}
          </div>

          <div className="flex w-full sm:w-1/2 sm:self-end lg:w-full lg:self-center">
            <Button className="w-full" white={!!item.price}>
              {item.price ? t("pricing_button") : t("contact_button")}
            </Button>
          </div>

          <ul>
            {item.features.map((feature, index) => (
              <li
                className="flex items-center border-t border-n-6 py-5"
                key={index}
              >
                <img src={check} width={24} height={24} alt="check" />
                <p className="body-2 ml-4">{t("pricing_feature_" + feature)}</p>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};

export default List;
