import { useTranslation } from "react-i18next";

import { beeLandscape } from "../../../assets";
import ClipPath from "../../../assets/svg/ClipPath";
import { features } from "../../../constants";
import { GradientLight } from "../../design/FeaturesDesigns";
import Heading from "../../shared/Heading";
import Section from "../../shared/Section";

const Features = () => {
  const { t } = useTranslation();

  return (
    <Section id="features">
      <div className="container relative z-2 pt-15 lg:pt-10">
        <Heading title="features_header" text="features_subtitle"></Heading>

        <div className="mb-10 flex flex-wrap justify-center gap-10">
          {features.map((feature) => (
            <div
              className="relative block bg-[length:100%_100%] bg-no-repeat p-0.5 lg:max-w-96"
              style={{ backgroundImage: `url(${feature.backgroundUrl})` }}
              key={feature.id}
            >
              <div className="pointer-events-none relative z-2 flex h-full min-h-[22rem] flex-col p-[1.4rem]">
                <h5 className="h5 mb-5 text-balance">
                  {t("feature_" + feature.name + "_title")}
                </h5>
                <p className="body-2 mb-6 text-balance text-n-3">
                  {" "}
                  {t("feature_" + feature.name + "_text")}
                </p>

                <div className="mt-auto flex items-center">
                  <img
                    src={feature.iconUrl}
                    width={48}
                    height={48}
                    alt={feature.title}
                  />
                </div>
              </div>

              {feature.light && <GradientLight />}

              <div
                className="absolute inset-0.5 bg-n-8"
                style={{ clipPath: "url(#feature)" }}
              >
                <div className="absolute inset-0 opacity-5 transition-opacity hover:opacity-20">
                  <img
                    src={beeLandscape}
                    className="size-full object-cover"
                    width={380}
                    height={362}
                    alt="bee"
                  />
                </div>
              </div>

              <ClipPath />
            </div>
          ))}
        </div>
      </div>
    </Section>
  );
};

export default Features;
