import { useTranslation } from "react-i18next";

import Tagline from "./Tagline";

const Heading = ({ className, title, text, tag }) => {
  const { t } = useTranslation();
  return (
    <div
      className={`mx-auto mb-12 max-w-[50rem] text-center lg:mb-20 ${className}`}
    >
      {tag && <Tagline className="mb-4 justify-center">{t(tag)}</Tagline>}
      {title && <h2 className="h2 text-balance md:text-center">{t(title)}</h2>}
      {text && (
        <p className="body-2 mt-4 text-balance text-center text-n-4">
          {t(text)}
        </p>
      )}
    </div>
  );
};

export default Heading;
