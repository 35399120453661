import { useTranslation } from "react-i18next";

import { beeEaseLogo, check } from "../../../assets";
import { benefits, keyFeaturesIcons } from "../../../constants";
import { LeftCurve, RightCurve } from "../../design/BenefitsDesigns";
import Button from "../../shared/Button";
import Section from "../../shared/Section";

const Benefits = () => {
  const { t } = useTranslation();

  return (
    <Section crosses id="benefits">
      <div className="container relative">
        <div className="flex w-full flex-col gap-20">
          <div className="flex flex-col items-center gap-5 lg:flex-row lg:gap-20">
            <div className="w-full text-center lg:text-left">
              <h2 className="h2">{t("benefits_header")}</h2>
            </div>

            <div className="w-full text-center">
              <p className="body-2 text-n-4">{t("benefits_subtitle")}</p>
            </div>
          </div>

          <div className="flex flex-col-reverse gap-20 lg:flex-row">
            <div className="flex w-full flex-col gap-10">
              <ul>
                {benefits.map((benefit) => (
                  <li className="mb-3 py-3" key={benefit.id}>
                    <div className="flex items-center">
                      <img src={check} width={24} height={24} alt="check" />
                      <h6 className="body-2 ml-5">
                        {" "}
                        {t("benefit_" + benefit.name + "_title")}
                      </h6>
                    </div>
                    <p className="body-2 mt-3 text-balance text-n-4">
                      {t("benefit_" + benefit.name + "_text")}
                    </p>
                  </li>
                ))}
              </ul>
            </div>

            <div className="relative w-full scale-90 sm:scale-100">
              <div className="relative left-1/2 flex aspect-square w-[22rem] -translate-x-1/2 rounded-full border border-n-6 lg:top-1/2 lg:-translate-y-1/2">
                <div className="m-auto flex aspect-square w-60 rounded-full border border-n-6">
                  <div className="m-auto aspect-square w-24 rounded-full bg-conic-gradient p-[0.2rem]">
                    <div className="flex size-full items-center justify-center rounded-full bg-n-8">
                      <img
                        src={beeEaseLogo}
                        width={60}
                        height={60}
                        alt="BeeEase"
                      />
                    </div>
                  </div>
                </div>

                <ul>
                  {keyFeaturesIcons.map((icon, index) => (
                    <li
                      // eslint-disable-next-line tailwindcss/no-custom-classname, tailwindcss/classnames-order
                      className={`absolute left-1/2 top-0 ml-[-2.075rem] h-1/2 origin-bottom rotate-${
                        index * 45
                      }`}
                      key={index}
                    >
                      <div
                        // eslint-disable-next-line tailwindcss/no-custom-classname, tailwindcss/classnames-order
                        className={`relative top-[-1.6rem] flex size-16 rounded-xl border border-n-1/15 bg-n-7 -rotate-${
                          index * 45
                        }`}
                      >
                        <img
                          src={icon}
                          className="m-auto"
                          width={40}
                          height={40}
                          alt={icon}
                        />
                      </div>
                    </li>
                  ))}
                </ul>

                {/*<LeftCurve />*/}

                <RightCurve />
              </div>
            </div>
          </div>

          <div className="flex flex-row-reverse lg:flex-row">
            <div className="w-full">
              <Button className="w-full">{t("benefits_try")}</Button>
            </div>
            <div className="w-full"></div>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default Benefits;
