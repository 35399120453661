const Generating = ({ title, className, img, imgAnimationClass }) => {
  return (
    <div
      className={`flex h-14 items-center rounded-[1.75rem] bg-n-8/80 px-6 ${
        className || ""
      } text-base`}
    >
      <img
        src={img}
        className={`h5 mr-4 w-5 ${imgAnimationClass || ""} `}
        alt={img}
      />
      {title}
    </div>
  );
};

export default Generating;
