import { useState } from "react";
import { useTranslation } from "react-i18next";

import { smallSphere, stars } from "../../../assets";
import { LeftLine, RightLine } from "../../design/PricingDesigns";
import Heading from "../../shared/Heading";
import Section from "../../shared/Section";
import List from "./List";

const Pricing = () => {
  const { t } = useTranslation();

  const [monthlyOptions, setMonthlyOptions] = useState(true);

  return (
    <Section className="overflow-hidden" id="pricing">
      <div className="container relative z-2">
        <div className="relative mb-[6.5rem] hidden justify-center lg:flex">
          <img
            src={smallSphere}
            className="relative z-1"
            width={255}
            height={255}
            alt="sphere"
          />

          <div className="pointer-events-none absolute left-1/2 top-1/2 w-[60rem] -translate-x-1/2 -translate-y-1/2">
            <img
              src={stars}
              className="w-full"
              width={950}
              height={400}
              alt="stars"
            />
          </div>
        </div>
        <Heading tag="pricing_tag" title="pricing_title" text="pricing_text" />
        <div className="mx-auto mb-10 w-[19rem] rounded-xl bg-gradient-to-b from-[#D77DEE]/90 to-n-1/15 p-0.25">
          <div className="flex rounded-[0.6875rem] bg-n-8 p-[0.1875rem]">
            <button
              className={`button h-10 flex-1 rounded-lg transition-colors ${
                monthlyOptions ? "bg-n-6" : ""
              }`}
              onClick={() => {
                setMonthlyOptions(true);
              }}
            >
              {t("pricing_monthly")}
            </button>
            <button
              className={`button h-10 flex-1 rounded-lg transition-colors ${
                monthlyOptions ? "" : "bg-n-6"
              }`}
              onClick={() => {
                setMonthlyOptions(false);
              }}
            >
              {t("pricing_annually")}
              <span className="ml-2.5 rounded bg-color-1 p-1">-10%</span>
            </button>
          </div>
        </div>
        <div className="relative">
          <List monthlyOptions={monthlyOptions} />
          <LeftLine />
          <RightLine />
        </div>
      </div>
    </Section>
  );
};

export default Pricing;
