import * as Sentry from "@sentry/react";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { email, paperPlane, shapes1, shapes2, user } from "../../../assets";
import Button from "../../shared/Button";
import Section from "../../shared/Section";

const Contact = () => {
  const { t } = useTranslation();

  const fullNameInput = useRef();
  const emailInput = useRef();
  const messageInput = useRef();

  const [formSubmitted, setFormSubmitted] = useState(false);

  const contactForm = async (event) => {
    event.preventDefault();

    Sentry.captureFeedback({
      name: String(fullNameInput.current.value),
      email: String(emailInput.current.value),
      message: String(messageInput.current.value),
    });

    setFormSubmitted(true);
  };

  return (
    <Section crosses id="contact">
      <div className="container relative">
        <div className="flex w-full flex-col gap-10 lg:flex-row">
          <div className="flex w-full flex-col justify-evenly text-center lg:text-start">
            <h3 className="h3 text-balance">{t("contact_title")}</h3>
            <p className="body-2 mt-4 text-n-4 ">{t("contact_text")}</p>
          </div>

          <div className="relative flex w-full">
            <div className="relative mx-auto w-full rounded-3xl bg-conic-gradient p-0.25">
              <div className="rounded-[1.4375rem] bg-n-8 px-9 py-10 lg:px-16 lg:py-[3.25rem]">
                {!formSubmitted && (
                  <form onSubmit={contactForm}>
                    <div className="relative mb-4 lg:mb-5">
                      <img
                        src={user}
                        alt="user"
                        width={24}
                        height={24}
                        className="pointer-events-none absolute left-0 top-4 inline-block w-6 align-top"
                      />
                      <input
                        className="h-14 w-full border-b border-n-1/15 bg-transparent pl-12 font-light outline-none transition-colors placeholder:text-n-4 focus:border-n-1/30"
                        ref={fullNameInput}
                        required
                        placeholder={t("contact_name")}
                        type="text"
                      />
                    </div>

                    <div className="relative mb-4 lg:mb-5">
                      <img
                        src={email}
                        alt="email"
                        width={24}
                        height={24}
                        className="pointer-events-none absolute left-0 top-4 inline-block w-6 align-top"
                      />
                      <input
                        className="h-14 w-full border-b border-n-1/15 bg-transparent pl-12 font-light outline-none transition-colors placeholder:text-n-4 focus:border-n-1/30"
                        ref={emailInput}
                        required
                        placeholder={t("contact_email")}
                        type="email"
                      />
                    </div>

                    <div className="relative mb-4 lg:mb-5">
                      <textarea
                        className="max-h-[25vh] w-full border-b border-n-1/15 bg-transparent px-1 font-light outline-none transition-colors placeholder:text-n-4 focus:border-n-1/30"
                        ref={messageInput}
                        required
                        rows={4}
                        placeholder={t("contact_message")}
                      ></textarea>
                    </div>

                    <Button className="w-full" white>
                      {t("contact_button")}
                    </Button>
                  </form>
                )}

                {formSubmitted && (
                  <div className="flex flex-col items-center gap-1">
                    <img
                      src={paperPlane}
                      alt="sent"
                      className="mx-auto"
                      width={150}
                      height={150}
                    />
                    <p className="body-2 max-w-96 text-balance text-center text-n-2">
                      {t("contact_success")}
                    </p>
                  </div>
                )}

                <div className="absolute inset-y-6 -right-12 hidden lg:flex">
                  <div className="w-6 rounded-r-3xl bg-[#1B1B2E]"></div>
                  <div className="my-12 w-6 rounded-r-3xl bg-[#1B1B2E]/50"></div>
                </div>
              </div>
            </div>

            <div className="hidden w-[48px] lg:inline-flex"></div>
          </div>
        </div>
      </div>

      <div className="pointer-events-none absolute left-[18.5rem] top-[-5.75rem] -z-1 w-[19.8125rem] lg:-top-15 lg:left-[5.5rem]">
        <img
          src={shapes1}
          alt="shapes1"
          className="inline-block w-full align-top opacity-100 transition-opacity"
        />
      </div>

      <div className="pointer-events-none absolute -bottom-28 right-60 -z-1 w-[28.1875rem] lg:-bottom-20 lg:right-7">
        <img
          src={shapes2}
          alt="shapes2"
          className="inline-block w-full align-top opacity-100 transition-opacity"
        />
      </div>
    </Section>
  );
};

export default Contact;
