import ButtonGradient from "./assets/svg/ButtonGradient";
import Header from "./components/Header";
import About from "./components/pages/about/About";
import Benefits from "./components/pages/benefits/Benefits";
import Contact from "./components/pages/contact/Contact";
import Features from "./components/pages/features/Features";
import Footer from "./components/pages/footer/Footer";
import HowToUse from "./components/pages/how-to-use/HowToUse";
import Pricing from "./components/pages/pricing/Pricing";
import Roadmap from "./components/pages/roadmap/Roadmap";

const App = () => {
  return (
    <>
      <div className="overflow-hidden pt-[4.75rem] lg:pt-[5.25rem]">
        <Header />
        <About />
        <Features />
        <Benefits />
        <HowToUse />
        <Pricing />
        <Roadmap />
        <Contact />
        <Footer />
      </div>
      <ButtonGradient />
    </>
  );
};
export default App;
