import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { ScrollParallax } from "react-just-parallax";

import { beeLandscape, curve } from "../../../assets";
import { loading } from "../../../assets";
import { aboutIcons } from "../../../constants";
import {
  BackgroundCircles,
  BottomLine,
  Gradient,
} from "../../design/AboutDesigns";
import Button from "../../shared/Button";
import Section from "../../shared/Section";
import Generating from "./Generating";

const About = () => {
  const { t } = useTranslation();

  const parallaxRef = useRef(null);

  return (
    <Section
      className="mt-[-5.25rem] pt-36"
      crosses
      crossesOffset="lg:translate-y-[5.25rem]"
      customPaddings
      id="about"
    >
      <div className="container relative" ref={parallaxRef}>
        <div className="relative z-1 mb-15 flex w-full justify-center text-center xl:mb-28">
          <div className="flex flex-col gap-10 sm:w-4/5 xl:gap-15">
            <h1 className="h1">
              {t("about_header")}{" "}
              <span className="relative inline-block">
                BeeEase{" "}
                <img
                  src={curve}
                  className="absolute left-0 top-full w-full xl:mt-2"
                  width={624}
                  height={28}
                  alt="curve"
                />
              </span>
            </h1>
            <p className="body-1 text-n-2">{t("about_subtitle")}</p>

            <div>
              <Button href="#features" white>
                {t("about_button")}
              </Button>
            </div>
          </div>
        </div>

        <div className="relative mx-auto max-w-[23rem] md:max-w-5xl">
          <div className="relative z-1 rounded-2xl bg-conic-gradient p-0.5">
            <div className="relative rounded-2xl bg-n-8">
              <div className="h-[1.4rem] rounded-t-[0.9rem] bg-n-10" />

              <div className="aspect-[33/40] overflow-hidden rounded-b-[0.9rem] md:aspect-[1456/816]">
                <img
                  src={beeLandscape}
                  className="relative top-[-44px] w-full md:top-[-265px] lg:top-[-325px]"
                  width={1456}
                  height={816}
                  alt="AI"
                />

                <Generating
                  title={t("about_generating")}
                  img={loading}
                  imgAnimationClass="animate-loading"
                  className="absolute inset-x-4 bottom-0 md:bottom-2 md:left-1/2 md:right-auto md:w-[31rem] md:-translate-x-1/2"
                />

                <ScrollParallax isAbsolutelyPositioned>
                  <ul className="absolute bottom-[7.5rem] left-[-5.5rem] hidden rounded-2xl border border-n-1/10 bg-n-9/10 p-1 backdrop-blur xl:flex">
                    {aboutIcons.map((icon, index) => (
                      <li className="p-5" key={index}>
                        <img src={icon} width={30} height={25} alt="icon" />
                      </li>
                    ))}
                  </ul>
                </ScrollParallax>
              </div>
            </div>

            <Gradient />
          </div>

          <BackgroundCircles />
        </div>
      </div>

      <BottomLine />
    </Section>
  );
};

export default About;
