import { curve1, curve2 } from "../../assets";

export const RightCurve = () => {
  return (
    <div className="pointer-events-none absolute left-full top-1/2 -mt-1 ml-10 hidden w-[6vw] xl:block 2xl:w-[24vw]">
      <img src={curve2} alt="Curve 2" />
    </div>
  );
};

export const LeftCurve = () => {
  return (
    <div className="pointer-events-none absolute right-full top-1/2 mr-10 mt-12 hidden w-full xl:block">
      <img src={curve1} width={400} height={100} alt="Curve 1" />
    </div>
  );
};
