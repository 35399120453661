import * as Sentry from "@sentry/react";

import {
  aboutCalendar,
  aboutChart,
  aboutEmployee,
  aboutGear,
  barChart,
  brainGears,
  calendar,
  chart,
  checkedCalendar,
  clock,
  coloredCalendar,
  customizations,
  elFlag,
  employee,
  enFlag,
  enhancements,
  featureCard1,
  featureCard2,
  featureCard3,
  featureCard4,
  featureCard5,
  featureCard6,
  future,
  gear,
  generation,
  keychain,
  launch,
  notification2,
  notification3,
  notification4,
  position,
  refresh,
  sliderControl,
  smiley,
  statistics,
  today,
  userGroup,
  version,
} from "../../src/assets";

export const navigation = [
  {
    id: "0",
    title: "features",
    url: "#features",
  },
  {
    id: "1",
    title: "benefits",
    url: "#benefits",
  },
  {
    id: "2",
    title: "how_to_use",
    url: "#how-to-use",
  },
  {
    id: "3",
    title: "pricing",
    url: "#pricing",
  },
  {
    id: "4",
    title: "roadmap",
    url: "#roadmap",
  },
  {
    id: "5",
    title: "contact",
    url: "#contact",
    onlyMobile: true,
  },
];

export const languages = [
  {
    id: "0",
    code: "en",
    icon: enFlag,
  },
  {
    id: "1",
    code: "el",
    icon: elFlag,
  },
];

export const aboutIcons = [aboutCalendar, aboutGear, aboutEmployee, aboutChart];

export const notificationImages = [notification2, notification3, notification4];

export const features = [
  {
    id: "0",
    name: "intelligence",
    backgroundUrl: featureCard1,
    iconUrl: brainGears,
  },
  {
    id: "1",
    name: "customization",
    backgroundUrl: featureCard2,
    iconUrl: sliderControl,
    light: true,
  },
  {
    id: "2",
    name: "management",
    backgroundUrl: featureCard3,
    iconUrl: userGroup,
  },
  {
    id: "3",
    name: "leaves",
    backgroundUrl: featureCard4,
    iconUrl: checkedCalendar,
    light: true,
  },
  {
    id: "4",
    name: "schedule",
    backgroundUrl: featureCard5,
    iconUrl: coloredCalendar,
  },
  {
    id: "5",
    name: "reporting",
    backgroundUrl: featureCard6,
    iconUrl: barChart,
  },
];

export const benefits = [
  {
    id: "0",
    name: "efficiency",
  },
  {
    id: "1",
    name: "optimization",
  },
  {
    id: "2",
    name: "satisfaction",
  },
  {
    id: "3",
    name: "scalability",
  },
];

export const keyFeaturesIcons = [
  calendar,
  version,
  employee,
  generation,
  keychain,
  position,
  statistics,
  today,
];

export const initSteps = ["account", "stores", "employees", "availabilities"];

export const generationStepsIcons = [chart, clock, gear, refresh, smiley];

export const roadmap = [
  {
    id: 0,
    name: "launch",
    imageUrl: launch,
    colorful: true,
  },
  {
    id: 1,
    name: "enhancements",
    imageUrl: enhancements,
  },
  {
    id: 2,
    name: "future",
    imageUrl: future,
  },
  {
    id: 3,
    name: "customizations",
    imageUrl: customizations,
    colorful: true,
  },
];

export const pricingPackages = [
  {
    id: 0,
    name: "basic",
    price: true,
    monthly: "29,99",
    annually: "323,89",
    features: ["two_stores", "ten_employees", "all_functionalities"],
  },
  {
    id: 1,
    name: "extended",
    price: true,
    monthly: "39,99",
    annually: "431,89",
    features: [
      "five_stores",
      "fifty_employees",
      "all_functionalities",
      "advanced_analytics",
    ],
  },
  {
    id: 2,
    name: "enterprise",
    price: false,
    features: [
      "unlimited_stores",
      "unlimited_employees",
      "all_functionalities",
      "advanced_analytics",
      "consulting_rules",
    ],
  },
];

Sentry.init({
  dsn: "https://1017bd75d91b9d6100023adb9ceb8955@o4507780400807936.ingest.de.sentry.io/4507822980726864",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
