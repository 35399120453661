import { useTranslation } from "react-i18next";

import { check2, grid, loadingAlt } from "../../../assets";
import { roadmap } from "../../../constants";
import { Gradient } from "../../design/RoadmapDesign";
import Heading from "../../shared/Heading";
import Section from "../../shared/Section";
import Tagline from "../../shared/Tagline";

const Roadmap = () => {
  const { t } = useTranslation();

  return (
    <Section className="overflow-hidden" id="roadmap">
      <div className="container pt-15 lg:py-10">
        <Heading title="roadmap_title" text="roadmap_text" />

        <div className="relative grid gap-5 lg:grid-cols-2 lg:gap-10 lg:pb-28">
          {roadmap.map((stage) => {
            return (
              <div
                className={`rounded-[2.5rem] p-0.25 lg:flex even:lg:translate-y-28 ${
                  stage.colorful ? "bg-conic-gradient" : "bg-n-6"
                }`}
                key={stage.id}
              >
                <div className="relative overflow-hidden rounded-[2.4375rem] bg-n-8 p-8 xl:p-15">
                  <div className="absolute left-0 top-0 max-w-full">
                    <img
                      className="w-full"
                      src={grid}
                      width={550}
                      height={550}
                      alt="Grid"
                    />
                  </div>
                  <div className="relative z-1">
                    <div className="mb-2 flex items-center justify-between">
                      <Tagline>{t("roadmap_" + stage.name + "_date")}</Tagline>

                      {stage.id < 2 && (
                        <div className="flex items-center rounded bg-n-1 px-4 py-1 text-n-8">
                          <img
                            className="mr-2.5"
                            src={stage.id === 0 ? check2 : loadingAlt}
                            width={16}
                            height={16}
                            alt={"status_" + stage.name}
                          />
                          <div className="tagline">
                            {stage.id === 0
                              ? t("roadmap_stage_done")
                              : t("roadmap_stage_in_progress")}
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="size-full px-10 sm:px-20 md:px-28 lg:px-5">
                      <img
                        className="object-fill"
                        src={stage.imageUrl}
                        width={628}
                        height={426}
                        alt={stage.name}
                      />
                    </div>
                    <h4 className="h4 mb-4">
                      {t("roadmap_" + stage.name + "_title")}
                    </h4>
                    <p className="body-2 text-wrap text-n-4">
                      {t("roadmap_" + stage.name + "_text")}
                    </p>
                  </div>
                </div>
              </div>
            );
          })}

          <Gradient />
        </div>
      </div>
    </Section>
  );
};

export default Roadmap;
