import { useTranslation } from "react-i18next";

import { beeEaseWhiteLogo, gradient, play } from "../../assets";
import ChatBubbleWing from "../../assets/svg/ChatBubbleWing";

export const Gradient = () => {
  return (
    <div className="pointer-events-none absolute -left-40 top-0 size-[56.625rem] opacity-50 mix-blend-color-dodge">
      <img
        className="absolute left-1/2 top-1/2 h-[88.5625rem] w-[79.5625rem] max-w-[79.5625rem] -translate-x-1/2 -translate-y-1/2"
        src={gradient}
        width={1417}
        height={1417}
        alt="Gradient"
      />
    </div>
  );
};

export const PhotoChatMessage = () => {
  const { t } = useTranslation();

  return (
    <div className="absolute left-8 top-10 rounded-t-xl rounded-bl-xl bg-[#410c3a] px-8 py-6 font-code text-base lg:top-8 lg:max-w-[17.5rem]">
      {t("onboarding_step2_overlay")}
      <ChatBubbleWing className="absolute bottom-0 left-full" />
    </div>
  );
};

export const VideoChatMessage = () => {
  const { t } = useTranslation();
  return (
    <div className="absolute w-full rounded-t-xl rounded-br-xl bg-n-6 pb-7 pl-5 pr-2.5 pt-2.5 font-code text-base">
      {t("onboarding_done_title")}
      <div className="absolute -bottom-9 left-[0.675rem] flex size-12 items-center justify-center rounded-xl bg-[#876aac]">
        <img src={beeEaseWhiteLogo} width={40} height={40} alt="Brainwave" />
      </div>
      <p className="tagline absolute bottom-1 right-2.5 text-[0.625rem] uppercase text-n-3">
        {t("onboarding_done_text")}
      </p>
      <ChatBubbleWing
        className="absolute bottom-0 right-full -scale-x-100"
        pathClassName="fill-n-6"
      />
    </div>
  );
};

export const VideoBar = () => {
  return (
    <div className="absolute bottom-0 left-0 flex w-full items-center p-6">
      <img
        src={play}
        width={24}
        height={24}
        alt="Play"
        className="mr-3 object-contain"
      />

      <div className="flex-1 bg-[#D9D9D9]">
        <div className="h-0.5 w-1/2 bg-color-1"></div>
      </div>
    </div>
  );
};
