import { useTranslation } from "react-i18next";

import { beeEaseLogo } from "../../../assets";
import Section from "../../shared/Section";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <Section crosses className="!px-0 !py-10">
      <div className="container relative flex flex-col">
        <div className="relative mb-10 flex flex-row items-center gap-5 self-center lg:self-start">
          <img src={beeEaseLogo} alt="beeEase Logo" width={80} height={80} />
          <span className="text-xl font-extrabold tracking-widest">
            BeeEase
          </span>
        </div>

        <div className="caption relative flex w-full flex-col  items-center gap-5 text-n-4 lg:flex-row lg:justify-between">
          <p>
            &#169; {new Date().getFullYear()}. {t("footer_rights")}
          </p>
          <p>
            Powered by{" "}
            <a
              className="font-bold text-color-1"
              href="https://ikmservices.gr"
              target="_blank"
              rel="noopener noreferrer"
            >
              IKM Services
            </a>
          </p>
        </div>
      </div>
    </Section>
  );
};

export default Footer;
