import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { disablePageScroll, enablePageScroll } from "scroll-lock";

import { beeEaseLogo } from "../assets";
import MenuSvg from "../assets/svg/MenuSvg";
import { navigation } from "../constants";
import { HamburgerMenu } from "./design/HeaderDesigns";
import Button from "./shared/Button";
import LangSelector from "./shared/LangSelector";

const Header = () => {
  const { t } = useTranslation();

  const pathname = useLocation();

  const [openNavigation, setOpenNavigation] = useState(false);

  const toggleNavigation = () => {
    if (openNavigation) {
      setOpenNavigation(false);
      enablePageScroll();
    } else {
      setOpenNavigation(true);
      disablePageScroll();
    }
  };

  const handleClick = () => {
    if (!openNavigation) {
      return;
    }

    enablePageScroll();
    setOpenNavigation(false);
  };

  return (
    <div
      className={`fixed left-0 top-0 z-50 w-full border-b border-n-6 lg:bg-n-8/90 lg:backdrop-blur-sm ${
        openNavigation ? "bg-n-8" : "bg-n-8/90 backdrop-blur-sm"
      }`}
    >
      <div className="flex items-center px-5 max-lg:py-4 lg:px-7.5 xl:px-10">
        <a className="block w-48 xl:mr-8" href="#about">
          <div className="relative flex flex-row items-center gap-5 self-center ">
            <img src={beeEaseLogo} alt="beeEase Logo" width={60} height={60} />
            <span className="text-lg font-bold tracking-widest">BeeEase</span>
          </div>
        </a>
        <nav
          className={` ${
            openNavigation ? "flex" : "hidden"
          } fixed inset-x-0 bottom-0 top-20 bg-n-8 lg:static lg:mx-auto lg:flex lg:bg-transparent`}
        >
          <div className="relative z-2 m-auto flex flex-col items-center justify-center lg:flex-row">
            {navigation.map((item) => (
              <a
                key={item.id}
                href={item.url}
                onClick={handleClick}
                className={`relative block font-code text-2xl uppercase text-n-1 transition-colors hover:text-color-1 ${
                  item.onlyMobile ? "lg:hidden" : ""
                } mg:-mr-0.25 p-6 md:py-8 lg:text-xs lg:font-semibold ${
                  item.url === pathname.hash
                    ? "z-2 lg:text-n-1"
                    : "lg:text-n-1/50"
                } lg:leading-5 lg:hover:text-n-1 xl:px-12`}
              >
                {t("header_" + item.title)}
              </a>
            ))}
            <LangSelector
              className={
                "m-6 flex size-12 bg-n-5 hover:bg-n-6 md:my-8 lg:hidden"
              }
              width={30}
              height={30}
            />
          </div>

          <HamburgerMenu />
        </nav>
        <Button className="mr-8 hidden lg:flex" href="#contact">
          {t("header_contact")}
        </Button>

        <LangSelector
          className={
            "hidden size-10 bg-n-6/50 opacity-50 hover:bg-n-6 hover:opacity-85 lg:flex"
          }
          width={24}
          height={24}
        />
        <Button
          className="ml-auto lg:hidden"
          px="px-3"
          onClick={toggleNavigation}
        >
          <MenuSvg openNavigation={openNavigation} />
        </Button>
      </div>
    </div>
  );
};

export default Header;
